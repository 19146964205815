var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TransferReport',{ref:"report",attrs:{"baseDate":_vm.baseDate}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 col-lg-12 order-lg-2 order-xl-1"},[_c('KTPortlet',{class:'kt-portlet--height-fluid',scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('GroupingTransactionDataTable',_vm._b({ref:"inoutTable"},'GroupingTransactionDataTable',{
              baseDate: _vm.baseDate,
              titleText: '이체거래 입출거래 상세',
              titleTextClass: 'text-seizeorange',
              detailData: _vm.detailInputDataArray,
              categoryName: '이체거래',
              filterDisabled: {
                term: false,
                inout: false,
                targetInst: false,
                name: false,
                level2: false,
                level3: false,
                level4: false,
                level5: false,
                tranType: false,
              },
              inoutInitialFilter: ['입금', '출금'],
              itemsPerPage: 10,
            },false))]},proxy:true}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 col-lg-12 order-lg-2 order-xl-1"},[_c('KTPortlet',{class:'kt-portlet--height-fluid',scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('GroupingTransactionDataTable',_vm._b({ref:"personalTable"},'GroupingTransactionDataTable',{
              baseDate: _vm.baseDate,
              titleText: '개인간 이체거래 입출거래 상세',
              titleTextClass: 'text-seizeorange',
              detailData: _vm.detailInputDataArray,
              categoryName: '이체거래',
              categoryName2: '개인',
              groupHeaders: [
                {
                  text: '성명',
                  align: 'center',
                  class: 'seize-table-header',
                  sortable: false,
                  value: 'name',
                },
                {
                  text: '입출구분',
                  align: 'center',
                  class: 'seize-table-header',
                  sortable: false,
                  value: 'inout_type',
                },
                {
                  text: '건수',
                  align: 'center',
                  class: 'seize-table-header',
                  sortable: false,
                  value: 'count',
                },
                {
                  text: '금액합계',
                  align: 'right',
                  class: 'seize-table-header',
                  sortable: false,
                  value: 'sum',
                } ],
              group1Function: function (data) {
                return data.capt_name;
              },
              filterDisabled: {
                term: false,
                inout: false,
                targetInst: false,
                name: false,
                level2: false,
                level3: false,
                level4: false,
                level5: false,
                tranType: false,
              },
              inoutInitialFilter: ['입금', '출금'],
              itemsPerPage: 10,
            },false))]},proxy:true}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }