<template>
  <div>
    <TransferReport ref="report" v-bind:baseDate="baseDate"></TransferReport>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-2 order-xl-1">
        <KTPortlet v-bind:class="'kt-portlet--height-fluid'">
          <template v-slot:body>
            <GroupingTransactionDataTable
              ref="inoutTable"
              v-bind="{
                baseDate: baseDate,
                titleText: '이체거래 입출거래 상세',
                titleTextClass: 'text-seizeorange',
                detailData: detailInputDataArray,
                categoryName: '이체거래',
                filterDisabled: {
                  term: false,
                  inout: false,
                  targetInst: false,
                  name: false,
                  level2: false,
                  level3: false,
                  level4: false,
                  level5: false,
                  tranType: false,
                },
                inoutInitialFilter: ['입금', '출금'],
                itemsPerPage: 10,
              }"
            >
            </GroupingTransactionDataTable>
          </template>
        </KTPortlet>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-2 order-xl-1">
        <KTPortlet v-bind:class="'kt-portlet--height-fluid'">
          <template v-slot:body>
            <GroupingTransactionDataTable
              ref="personalTable"
              v-bind="{
                baseDate: baseDate,
                titleText: '개인간 이체거래 입출거래 상세',
                titleTextClass: 'text-seizeorange',
                detailData: detailInputDataArray,
                categoryName: '이체거래',
                categoryName2: '개인',
                groupHeaders: [
                  {
                    text: '성명',
                    align: 'center',
                    class: 'seize-table-header',
                    sortable: false,
                    value: 'name',
                  },
                  {
                    text: '입출구분',
                    align: 'center',
                    class: 'seize-table-header',
                    sortable: false,
                    value: 'inout_type',
                  },
                  {
                    text: '건수',
                    align: 'center',
                    class: 'seize-table-header',
                    sortable: false,
                    value: 'count',
                  },
                  {
                    text: '금액합계',
                    align: 'right',
                    class: 'seize-table-header',
                    sortable: false,
                    value: 'sum',
                  },
                ],
                group1Function: function (data) {
                  return data.capt_name;
                },
                filterDisabled: {
                  term: false,
                  inout: false,
                  targetInst: false,
                  name: false,
                  level2: false,
                  level3: false,
                  level4: false,
                  level5: false,
                  tranType: false,
                },
                inoutInitialFilter: ['입금', '출금'],
                itemsPerPage: 10,
              }"
            >
            </GroupingTransactionDataTable>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import GroupingTransactionDataTable from "@/views/partials/widgets/tables/GroupingTransactionDataTable.vue";
import TransferReport from "@/views/partials/widgets/reports/TransferReport";

export default {
  name: "Transfer",
  components: {
    TransferReport,
    KTPortlet,
    GroupingTransactionDataTable,
  },
  data() {
    return {
      baseDate: this.$store.getters.baseDate,
      detailInputDataArray: this.$store.getters.requestItem.items,
    };
  },
  mounted() {
    document.title = "이체거래";
  },
  methods: {
    updateView() {
      this.baseDate = this.$store.getters.baseDate;
      this.detailInputDataArray = this.$store.getters.requestItem.items;
      this.$refs.report.updateView();
      this.$refs.inoutTable.setData(this.baseDate, this.detailInputDataArray);
      this.$refs.personalTable.setData(
        this.baseDate,
        this.detailInputDataArray
      );
    },
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
